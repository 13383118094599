import Ajv from "ajv";
import AGENT_BASIC_INFO from '../../node_modules/agent-basic-info/package.json';

const ajv = new Ajv({
  useDefaults: true,
  allErrors: true,
  jsonPointers: true
});

class ValidationService {
  validators = {}

  init = () => {
    if(AGENT_BASIC_INFO["app-framework"] && AGENT_BASIC_INFO["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(AGENT_BASIC_INFO["app-framework"]["actions"])
        .map(([name, value]) => [`@agent-basic-info/${name}`, ajv.compile(value)]))
      };
    }
  };

  validateable = (action) => this.validators[action.type]

  validate = async (action) => this.validators[action.type](action.payload)
}

const inst = new ValidationService();
inst.init();
export default inst;
