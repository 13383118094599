import { all } from 'redux-saga/effects';
  import AGENT_BASIC_INFO from 'agent-basic-info';

import configSaga from './configSaga';
import languageSaga from './languageSaga';
import navSaga from "./navSaga";

export default function* rootSaga() {
  yield all([
    ...configSaga,
    ...languageSaga,
    ...navSaga,
  ...(AGENT_BASIC_INFO.sagas || []),
  ]);
}
