import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage/session'
import rootReducer from 'reducers';
import rootSaga from 'sagas/rootSaga';
import thunk from 'redux-thunk';
import AGENT_BASIC_INFO from 'agent-basic-info';
import page654274a79bb3d1847e3e5de3Json from 'pages/page_654274a79bb3d1847e3e5de3.json';
import routesJson from 'pages/routes.json';
import genericPagesJson from '../pages/genericPages.json';
import sharedPagesJson from '../pages/sharedPages.json';

import ValidationService from '../services/ValidationService.js';
import { history } from '../sharedHistory';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {
  pages: {
    page_654274a79bb3d1847e3e5de3: page654274a79bb3d1847e3e5de3Json,
    routes: routesJson,
    genericPages: genericPagesJson,
    sharedPages:sharedPagesJson,
    menuItems: [
    {
      route: '/',
      name: 'page1'
    },
    ]
  }
};

const authItem = localStorage.getItem('auth');
if (authItem) {
  initialState.authentication = JSON.parse(authItem);
}

const sagaMiddleware = createSagaMiddleware();

const wrapper = (pckg, mid) => store => next => action => {
  if(action.type.startsWith(`@${pckg}/`)) {
    return mid(store)(next)(action);
  } else {
    return next(action);
  }
};


const validator = store => next => action => {
  if(ValidationService.validateable(action)) {
    ValidationService.validate(action)
    .then(res => {
      if(res) {
        console.log('Successfull validation:', action);
      } else {
        console.log('Invalid payload contents', action);
      }
    });
  }
  return next(action);
}


const middlewares = [
  ...(AGENT_BASIC_INFO.middlewares ? AGENT_BASIC_INFO.middlewares.map(mid => wrapper('agent-basic-info',mid)) : []),
];

const persistConfig = {
  key: "root",
  storage: storage,
  version: 0,
  whitelist: ["props"] // This is for core app reducers, widgets have their own 'persistedReducer'
};

const persistedReducer = persistReducer(
  persistConfig,
  rootReducer
);

const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), validator, sagaMiddleware, thunk, ...middlewares))
);

const persistor = persistStore(store);


sagaMiddleware.run(rootSaga);

export { store, persistor };

